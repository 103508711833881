import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "area-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_row, { class: "area-report" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_col, { cols: "12" }, {
        default: _withCtx(() => [
          _createVNode($setup["HeaderPage"], { data: $setup.dataHeader }, null, 8 /* PROPS */, ["data"]),
          _createElementVNode("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.dataContent, (item, index) => {
              return (_openBlock(), _createBlock($setup["ContentItem"], {
                key: String(index),
                data: item
              }, null, 8 /* PROPS */, ["data"]))
            }), 128 /* KEYED_FRAGMENT */))
          ])
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}