import { defineComponent as _defineComponent } from 'vue'
import { ref } from 'vue';
import HeaderPage from './components/HeaderPage.vue';
import ContentItem from './components/ContentItem.vue';
import type { IData } from './components/ContentItem.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'ReportPage',
  setup(__props, { expose: __expose }) {
  __expose();

const dataHeader = ref({
  name: 'João da Silva',
  company: 'GoAhead',
  type: 'Desenvolvimento',
  date: '01/01/2021',
  id: '#00',
});

const dataContent = ref<IData[]>([
  {
    title: 'Texto de Exemplo',
    type: 'text',
    text: 'Lorem ipsum dolor sit amet consectetur. Tincidunt morbi enim viverra ipsum egestas augue. Vivamus risus mi vitae auctor enim at lobortis pretium. Senectus lorem sit sapien interdum nunc curabitur et lacus tincidunt. Nunc commodo pretium tellus diam aliquet sit. Id sit mauris sapien libero nisl semper bibendum nulla. Amet enim eget fames consectetur in libero non proin. Iaculis commodo pellentesque pulvinar pretium. Nec mus ipsum cursus habitant ipsum vel eget. Posuere sagittis neque nunc ultricies scelerisque donec enim sagittis. Ut tellus varius feugiat arcu nulla id. Consequat posuere nam tempus ut ut sollicitudin augue.',
  },
  {
    title: 'Gráfico de Vendas',
    type: 'graph',
    series: [
      {
        name: 'Vendas',
        data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      },
    ],
  },
  {
    title: 'Cards de Exemplo',
    type: 'card',
    cards: [
      {
        title: 'Card 1',
        text: 'Lorem ipsum dolor sit amet consectetur. Tincidunt morbi enim viverra ipsum egestas augue.',
      },
      {
        title: 'Card 2',
        text: 'Lorem ipsum dolor sit amet consectetur. Tincidunt morbi enim viverra ipsum egestas augue.',
      },
      {
        title: 'Card 2',
        text: 'Lorem ipsum dolor sit amet consectetur. Tincidunt morbi enim viverra ipsum egestas augue.',
      },
    ],
  },
]);

const __returned__ = { dataHeader, dataContent, HeaderPage, ContentItem }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})