import AtomIcon from "./AtomIcon.vue"
import AtomTooltip from "./AtomTooltip.vue"
import AtomTitle from "./AtomTitle.vue"
import AtomSubTitle from "./AtomSubTitle.vue"
import AtomInput from "./AtomInput.vue"
import AtomUpload from "./AtomUpload.vue"
import AtomProgressBar from "./AtomProgressBar.vue"
import AtomDatePicker from "./AtomDatePicker.vue"
import AtomButton from "./AtomButton.vue"
import AtomInfoCard from "./AtomInfoCard.vue"
import AtomBadge from "./AtomBadge.vue"
import AtomSelect from "./AtomSelect.vue"
import AtomProgressForm from "./AtomProgressForm.vue"
import AtomProgressSaltos from "./AtomProgressSaltos.vue"
import AtomPreloader from "./AtomPreloader.vue"
import AtomInputMoney from "./AtomInputMoney.vue"
import AtomMonthPicker from "./AtomMonthPicker.vue"
import AtomCheckBox from "./AtomCheckBox.vue"
import AtomLegend from "./AtomLegend.vue"
import AtomChartQuadrant from "./AtomChartQuadrant.vue"
import AtomNotification from "./AtomNotification.vue"
import AtomAccordion from "./AtomAccordion.vue"

import IconLogo from "./icons/IconLogo.vue"


//icons
import EditPencil from "./icons/EditPencil.vue"
import ProvisionedIcon from "./icons/ProvisionedIcon.vue"
import ClockIcon from "./icons/ClockIcon.vue"
import ArrowLeft from "./icons/ArrowLeft.vue"
import EyeIcon from "./icons/EyeIcon.vue"
import MoreHorizontalIcon from './icons/MoreHorizontalIcon.vue'
import ActivityIcon from './icons/ActivityIcon.vue'
import MessageIcon from './icons/MessageIcon.vue'
import LockIcon from './icons/LockIcon.vue'
import ShowIcon from './icons/ShowIcon.vue'
import CallingIcon from './icons/CallingIcon.vue'
import CategoryIcon from './icons/CategoryIcon.vue'
import ChartIcon from './icons/ChartIcon.vue'
import GraphIcon from './icons/GraphIcon.vue'
import HomeIcon from './icons/HomeIcon.vue'
import HomeIcon2 from './icons/HomeIcon2.vue'
import LogoutIcon from './icons/LogoutIcon.vue'
import ProfileIcon from './icons/ProfileIcon.vue'
import PasswordSuccessIcon from './icons/PasswordSuccessIcon.vue'
import EmailIcon from './icons/EmailIcon.vue'
import SettingIcon from './icons/SettingIcon.vue'
import Filter2Icon from './icons/Filter2Icon.vue'
import NotificationIcon from './icons/NotificationIcon.vue'
import ArrowDown3Icon from './icons/ArrowDown3Icon.vue'
import PaperPlusIcon from './icons/PaperPlusIcon.vue'
import PaperIcon from './icons/PaperIcon.vue'
import DeletexIcon from './icons/DeletexIcon.vue'
import SearchIcon from './icons/SearchIcon.vue'
import CalendarIcon from './icons/CalendarIcon.vue'
import FolderIcon from './icons/FolderIcon.vue'
import EditIcon from './icons/EditIcon.vue'
import PaperUploadIcon from './icons/PaperUploadIcon.vue'
import DownloadIcon from './icons/DownloadIcon.vue'
import AlertIcon from './icons/AlertIcon.vue'
import ArrowDownCircleIcon from './icons/ArrowDownCircleIcon.vue'
import DangerTriangleIcon from './icons/DangerTriangleIcon.vue'
import ShieldDoneIcon from './icons/ShieldDoneIcon.vue'
import ShieldFailIcon from './icons/ShieldFailIcon.vue'
import ArrowDownIcon from './icons/ArrowDownIcon.vue'
import ArrowUpIcon from './icons/ArrowUpIcon.vue'
import LoaderIcon from './icons/LoaderIcon.vue'
import AddUserIcon from './icons/AddUserIcon.vue'
import DeleteIcon from './icons/DeleteIcon.vue'
import EditSquareIcon from './icons/EditSquareIcon.vue'
import LocationIcon from './icons/LocationIcon.vue'
import WorkIcon from './icons/WorkIcon.vue'
import UsersIcon from './icons/UsersIcon.vue'
import StepActiveIcon from './icons/StepActiveIcon.vue'
import CompletedStepIcon from './icons/CompletedStepIcon.vue'
import InfoSquareIcon from './icons/InfoSquareIcon.vue'
import PlusIcon from './icons/PlusIcon.vue'
import ArrowRightIcon from './icons/ArrowRightIcon.vue'
import BtnRightIcon from './icons/BtnRightIcon.vue'
import ColsIcon from './icons/ColsIcon.vue'
import BtnChevronLeft from './icons/BtnChevronLeft.vue'
import BtnChevronRight from './icons/BtnChevronRight.vue'
import DobbleLeftIcon from './icons/DobbleLeftIcon.vue'
import BtnDocumentIcon from './icons/BtnDocumentIcon.vue'
import warningIcon from './icons/warningIcon.vue'
import DiscountCoinIcon from './icons/DiscountCoinIcon.vue'
import ChevronDownIcon from './icons/ChevronDownIcon.vue'
import VersionIcon from './icons/VersionIcon.vue'
import DangerTriangleFullIcon from './icons/DangerTriangleFullIcon.vue'
import logoutModalIcon from './icons/logoutModalIcon.vue'
import XIcon from './icons/XIcon.vue'
import UserFullIcon from './icons/UserFullIcon.vue'
import BellIcon from './icons/BellIcon.vue'
import ArrowCircleRightIcon from './icons/ArrowCircleRightIcon.vue'
import IconChevronLeft from './icons/IconChevronLeft.vue'
import IconChevronRight from './icons/IconChevronRight.vue'
import CirclePointer from './icons/CirclePointer.vue'
import SettingsColorIcon from './icons/SettingsColorIcon.vue'
import PlusElipseIcon from './icons/PlusElipseIcon.vue'
import lineIcon from './icons/lineIcon.vue'
import DangerIcon from './icons/DangerIcon.vue'
import DocumentUploadIcon from './icons/DocumentUploadIcon.vue'
import TrashIcon from './icons/TrashIcon.vue'
import CheckColor from './icons/CheckColor.vue'
import DolarIcon from './icons/DolarIcon.vue'
import FilterFloatIcon from './icons/FilterFloatIcon.vue'
import BarMobileIcon from './icons/BarMobileIcon.vue'
import RegisterIcon from './icons/RegisterIcon.vue'
import ProfileMobileIcon from './icons/ProfileMobileIcon.vue'
import GraphIconColor from './icons/GraphIconColor.vue'
import lockLoginIcon from './icons/lockLoginIcon.vue'
import ProfileIconLogin from './icons/ProfileIconLogin.vue'
import LoginIcon from './icons/LoginIcon.vue'
import LoginPartBottomIcon from './icons/LoginPartBottomIcon.vue'
import LoginPartTopIcon from './icons/LoginPartTopIcon.vue'
import LogoGoIcon from "./icons/LogoGoIcon.vue"
import LogoGoOrangeIcon from "./icons/LogoGoOrangeIcon.vue"
import ArrowLeftMobileIcon from "./icons/ArrowLeftMobileIcon.vue"
import filterBlackIcon from "./icons/filterBlackIcon.vue"
import SearchIconMobile from "./icons/SearchIconMobile.vue"
import ArrowCircleIcon from "./icons/ArrowCircleIcon.vue"
import ArrowCircleRightWhiteIcon from "./icons/ArrowCircleRightWhiteIcon.vue"
import rectangleColorIcon from "./icons/rectangleColorIcon.vue"
import rectangleIcon from "./icons/rectangleIcon.vue"
import ArrowIconBlue from "./icons/ArrowIconBlue.vue"
import ArrowIconGreen from "./icons/ArrowIconGreen.vue"
import PlusTransparentIcon from "./icons/PlusTransparentIcon.vue"
import DeleteClear from "./icons/DeleteClear.vue"
import timerIcon from "./icons/timerIcon.vue"
import exportIcon from "./icons/exportIcon.vue"
import RelatoryIcon from "./icons/RelatoryIcon.vue"
import ElipsiIcon from "./icons/ElipsiIcon.vue"
import PauseIcon from "./icons/PauseIcon.vue"
import PlayIcon from "./icons/PlayIcon.vue"
import RestartIcon from "./icons/RestartIcon.vue"
import StopIcon from "./icons/StopIcon.vue"
import ZeroTouchIcon from "./icons/ZeroTouchIcon.vue"


import AtomToogleButton from './AtomToogleButton.vue'
import IconExpandir from './icons/IconExpandir.vue'
import IconAnalisys from './icons/IconAnalisys.vue'
import DeletexIconCircle from './icons/DeletexIconCircle.vue'
import FilterIcon from './icons/FilterIcon.vue'
import SearchIcon2 from './icons/SearchIcon2.vue'
import InfoIcon from './icons/InfoIcon.vue'
import CleanFilter from './icons/CleanFilter.vue'
import FiltersSearch from './icons/FiltersSearch.vue'
import ReorderIcon from './icons/ReorderIcon.vue'
import OfflineIcon from './icons/OfflineIcon.vue'
import OnlineIcon from './icons/OnlineIcon.vue'
import DownloadIcon2 from './icons/DownloadIcon2.vue'
import DeleteCircleIcon from './icons/DeleteCircleIcon.vue'

export {

    AtomProgressSaltos,
    warningIcon,
    AtomInputMoney,
    AtomButton,
    AtomMonthPicker,
    AtomCheckBox,
    AtomBadge,
    CheckColor,
    AtomDatePicker,
    AtomInfoCard,
    AtomProgressBar,
    AtomUpload,
    AtomIcon,
    DolarIcon,
    IconChevronLeft,
    IconChevronRight,
    CirclePointer,
    AtomTooltip,
    AtomTitle,
    AtomPreloader,
    AtomSubTitle,
    AtomInput,
    MessageIcon,
    DangerIcon,
    DocumentUploadIcon,
    TrashIcon,
    lineIcon,
    ShieldDoneIcon,
    MoreHorizontalIcon,
    AddUserIcon,
    LocationIcon,
    EditSquareIcon,
    DeleteIcon,
    DangerTriangleIcon,
    ShieldFailIcon,
    BtnDocumentIcon,
    LoaderIcon,
    LockIcon,
    EditIcon,
    ArrowDownIcon,
    PaperUploadIcon,
    BtnRightIcon,
    ArrowDownCircleIcon,
    PasswordSuccessIcon,
    ProfileIcon,
    EmailIcon,
    BtnChevronLeft,
    BtnChevronRight,
    ArrowRightIcon,
    CalendarIcon,
    ArrowDown3Icon,
    AtomNotification,
    SettingsColorIcon,
    EyeIcon,
    BellIcon,
    ProvisionedIcon,
    PaperPlusIcon,
    OfflineIcon,
    SearchIcon,
    PlusElipseIcon,
    DownloadIcon2,
    OnlineIcon,
    ArrowCircleRightIcon,
    UsersIcon,
    FolderIcon,
    PaperIcon,
    DeletexIcon,
    StepActiveIcon,
    ActivityIcon,
    NotificationIcon,
    InfoSquareIcon,
    PlusIcon,
    SettingIcon,
    DiscountCoinIcon,
    ChevronDownIcon,
    DownloadIcon,
    Filter2Icon,
    CompletedStepIcon,
    ShowIcon,
    CallingIcon,
    LogoutIcon,
    WorkIcon,
    ColsIcon,
    HomeIcon,
    HomeIcon2,
    ChartIcon,
    CategoryIcon,
    GraphIcon,
    DeleteCircleIcon,
    AlertIcon,
    ArrowUpIcon,
    AtomToogleButton,
    AtomSelect,
    AtomProgressForm,
    AtomLegend,
    AtomAccordion,
    AtomChartQuadrant,
    ArrowLeft,
    ClockIcon,
    VersionIcon,
    DangerTriangleFullIcon,
    logoutModalIcon,
    XIcon,
    UserFullIcon,
    DobbleLeftIcon,
    FilterFloatIcon,
    BarMobileIcon,
    RegisterIcon,
    ProfileMobileIcon,
    GraphIconColor,
    lockLoginIcon,
    ProfileIconLogin,
    LoginIcon,
    LoginPartBottomIcon,
    LoginPartTopIcon,
    LogoGoIcon,
    LogoGoOrangeIcon,
    ArrowLeftMobileIcon,
    filterBlackIcon,
    SearchIconMobile,
    ArrowCircleIcon,
    ArrowCircleRightWhiteIcon,
    rectangleColorIcon,
    rectangleIcon,
    ArrowIconBlue,
    ArrowIconGreen,
    PlusTransparentIcon,
    DeleteClear,
    timerIcon,
    exportIcon,
    RelatoryIcon,
    ElipsiIcon,
    PauseIcon,
    PlayIcon,
    RestartIcon,
    StopIcon,
    ZeroTouchIcon,
    IconLogo,
    IconExpandir,
    IconAnalisys,
    EditPencil,
    DeletexIconCircle,
    FilterIcon,
    SearchIcon2,
    InfoIcon,
    CleanFilter,
    FiltersSearch,
    ReorderIcon
}