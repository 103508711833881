import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-space-between align-center area-header" }
const _hoisted_2 = { class: "title text-code" }
const _hoisted_3 = { class: "mt-8 area-data" }
const _hoisted_4 = { class: "item title" }
const _hoisted_5 = { class: "value subtitle" }
const _hoisted_6 = { class: "item title" }
const _hoisted_7 = { class: "value subtitle" }
const _hoisted_8 = { class: "item title" }
const _hoisted_9 = { class: "value subtitle" }
const _hoisted_10 = { class: "item title" }
const _hoisted_11 = { class: "value subtitle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_divider = _resolveComponent("v-divider")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[0] || (_cache[0] = _createElementVNode("div", null, [
        _createElementVNode("img", {
          src: "/assets/Logo-GoAhead.svg",
          alt: "logo"
        })
      ], -1 /* HOISTED */)),
      _cache[1] || (_cache[1] = _createElementVNode("h1", { class: "title stroke" }, [
        _createTextVNode("Relatório - "),
        _createElementVNode("span", { class: "text-primary-nav" }, "GoConnect")
      ], -1 /* HOISTED */)),
      _createElementVNode("div", null, [
        _createElementVNode("span", _hoisted_2, _toDisplayString($setup.props.data.id), 1 /* TEXT */)
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("p", _hoisted_4, [
        _cache[2] || (_cache[2] = _createTextVNode(" Nome: ")),
        _createElementVNode("span", _hoisted_5, _toDisplayString($setup.props.data.name), 1 /* TEXT */)
      ]),
      _createElementVNode("p", _hoisted_6, [
        _cache[3] || (_cache[3] = _createTextVNode(" Empresa: ")),
        _createElementVNode("span", _hoisted_7, _toDisplayString($setup.props.data.company), 1 /* TEXT */)
      ]),
      _createElementVNode("p", _hoisted_8, [
        _cache[4] || (_cache[4] = _createTextVNode(" Tipo: ")),
        _createElementVNode("span", _hoisted_9, _toDisplayString($setup.props.data.type), 1 /* TEXT */)
      ]),
      _createElementVNode("p", _hoisted_10, [
        _cache[5] || (_cache[5] = _createTextVNode(" Data: ")),
        _createElementVNode("span", _hoisted_11, _toDisplayString($setup.props.data.date), 1 /* TEXT */)
      ])
    ]),
    _createVNode(_component_v_divider, { class: "my-10" })
  ]))
}