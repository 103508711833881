import { defineComponent as _defineComponent } from 'vue'
import { OrganismsDetailChart } from '@/components/organisms';

export interface IData {
  title?: string;
  type?: 'graph' | 'text' | 'card';
  text?: string;
  series?: any;
  cards?: { title: string; text: string }[];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ContentItem',
  props: {
    data: { type: Object, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;

const __returned__ = { props, get OrganismsDetailChart() { return OrganismsDetailChart } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})