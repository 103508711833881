import { defineComponent as _defineComponent } from 'vue'
import { ref, watch } from 'vue';
import VueHighcharts from 'vue3-highcharts';
import Highcharts from 'highcharts'; // Importação do Highcharts

import { MoleculeCardSimple } from '@/components/molecules';
import { ReorderIcon } from '@/components/atoms';

// Definindo as props para receber os dados dinamicamente

export default /*@__PURE__*/_defineComponent({
  __name: 'OrganismsQualityChart',
  props: {
  data: {
    type: Array,
    required: true,
    default: () => [],
  },
  title: {
    type: String,
    default: '',
  },
  tooltip: {
    type: String,
    default: '',
  },
  pointStart: {
    type: Number,
    default: Date.UTC(2023, 11, 1, 0, 0), // Valor padrão (pode ser ajustado)
  },
  pointInterval: {
    type: Number,
    default: 3600 * 1000, // Intervalo padrão de 1 hora
  },
},
  emits: ['clickIconTitle'],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;

const load = ref<boolean>(false);
const emit = __emit;

// Definindo a variável chartOptions que será atualizada dinamicamente
const chartOptions = ref<any>({
  chart: {
    type: 'areaspline',
    height: '350px',
    backgroundColor: 'transparent',
  },
  title: {
    text: '', // Usando o título da prop
  },
  plotOptions: {
    series: {
      pointStart: props.pointStart, // Usando o ponto de início da prop
      pointInterval: props.pointInterval, // Usando o intervalo de ponto da prop
    },
    areaspline: {
      color: '#FF5C00',
      fillColor: {
        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
        stops: [
          [0, '#FF5C0020'],
          [1, '#FF5C0000'],
        ],
      },
      threshold: null,
      marker: {
        lineWidth: 1,
        lineColor: null,
        fillColor: 'white',
      },
    },
  },
  yAxis: {
    min: 0,
    max: 100,
    title: {
      text: '',
    },
    labels: {
      style: {
        color: 'var(--color-title)', // Cor das labels do eixo Y
      },
      format: '{value}%', // Formato em porcentagem
    },
  },
  xAxis: {
    type: 'datetime',
    // tickInterval: props.pointInterval, // Usando o intervalo de ponto
    labels: {
      style: {
        color: 'var(--color-title)', // Cor das labels do eixo Y
      },
      format: '{value:%H:%M}', // Exibe as horas no formato correto
    },
  },
  legend: {
    itemStyle: {
      color: 'var(--color-title)', // Cor dos itens da legenda
    },
    gridLineColor: 'rgba(0, 0, 0, 0.1)', // Personalização visual do eixo
  },
  tooltip: {
    formatter: function () {
      return `<strong>${Highcharts.dateFormat('%H:%M', this.x)}</strong><br/>
            <strong>${this.series.name}</strong><br/>
            ${this.y}%`;
    },
  },
  // Usando a prop data para criar múltiplas séries
  series: props.data,
});

// Assinando para reatividade dos dados passados via props
watch(
  () => props.data,
  (newData) => {
    load.value = true;
    chartOptions.value.series = newData; // Atualiza as séries dinamicamente

    setTimeout(() => {
      load.value = false;
    }, 500);
  },
);

const __returned__ = { props, load, emit, chartOptions, get VueHighcharts() { return VueHighcharts }, get MoleculeCardSimple() { return MoleculeCardSimple }, get ReorderIcon() { return ReorderIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})