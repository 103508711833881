import { defineComponent as _defineComponent } from 'vue'
import { ref } from 'vue';
import {
  AtomSubTitle,
  AtomTitle,
  FilterIcon,
  SearchIcon2,
  AtomNotification,
  AtomCheckBox,
  CleanFilter,
  FiltersSearch,
  // AtomAccordion,
  InfoIcon,
  FilterFloatIcon,
  LogoutIcon,
} from '../atoms';
import { getUnidadesPorUsuario } from '@/core/services/UnitsService';
import { Authentication } from '@/store';
import { computed, onMounted } from 'vue';
import { watch } from 'vue';
import { MoleculeModal, MoleculeSheetBottom } from '../molecules';
import FormLogin from '@/pages/meusdados/components/FormLogin.vue';
import { state, actions } from '@/store/modules/layout';

import { FiltersUnit } from '@/store';
// import AtomToogleButton from '../atoms/AtomToogleButton.vue';
import {
  IOptionsSelect,
  // IToggleButon
} from '@/types';
import AtomSelect from '../atoms/AtomSelect.vue';
import MoleculeModalAlert from '../molecules/MoleculeModalAlert.vue';
import router from '@/router';

interface IProps {
  title?: string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'OrganismsBar',
  props: {
    title: { type: String, required: false }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const showModal = ref<boolean>(false);

// const buttonsDashboard = ref<IToggleButon[]>([
//   { text: 'Atual', value: 'atual' },
//   { text: 'Preditivo', value: 'predict' },
// ]);
const props = __props;
const up = ref<boolean>();
const showModalProfile = ref<boolean>(false);
const showModalFIlter = ref<boolean>(false);
const optionsDropDown = ref<IOptionsSelect[]>([
  { value: 1, text: 'Empresa 1' },
  { value: 2, text: 'Empresa 2' },
  { value: 3, text: 'Empresa 3' },
]);
const searchlink = ref('');
const filtersOptions = ref<any>([]);
const filter = ref<any>({});
const paddingLeft = computed(() => (state.menuRecolhido ? '72px' : '265px'));

const filtersOptionsSelected = computed(() => {
  return filtersOptions.value?.filter((x) => x.checked === true);
});

const filtersUnits = computed(() => {
  let units = [];
  if (!filter.value.empresa) {
    return units;
  }
  units = JSON.parse(
    JSON.stringify(filtersOptions.value?.filter((x) => filter.value.empresa.find((f) => f == x.companhiaId))),
  );
  if (searchlink.value) units = units.filter((x) => x.nome.toLowerCase().includes(searchlink.value.toLowerCase()));
  return units;
});

function handleClearFilter() {
  filtersOptions.value.map((f) => {
    f.checked = false;
  });
  filtersUnits.value.map((f) => {
    f.checked = false;
  });

  handleFilter(false);
}

function handleLogout() {
  Authentication.actions.logout();
  router.push({ name: 'Login' });
}
function handleFilter(fecha: boolean = true) {
  console.log('handleFilter');
  FiltersUnit.actions.ActionSetFilter(
    JSON.parse(JSON.stringify(filtersOptions.value.filter((x) => x.checked === true).map((x) => x.id))),
  );
  showModalFIlter.value = !fecha;
}

function handleCancelFilter() {
  showModalFIlter.value = false;
}

function getFunction() {
  return (Authentication.state.papelLista ?? []).length > 1
    ? `${Authentication.state.papelLista?.map((f) => f.nome)[0]}, ...`
    : Authentication.state.papelLista?.map((f) => f.nome)[0];
}

function acionGetUnidadesPorUsuario() {
  if (props.title !== 'Dashboard' && props.title !== 'Informações financeiras' && props.title !== 'Análises Gerais')
    return;
  getUnidadesPorUsuario(Authentication.state.id as number).then((res) => {
    filtersOptions.value = JSON.parse(JSON.stringify(res.filter((filter) => filter.archived == 0)));

    optionsDropDown.value = JSON.parse(
      JSON.stringify(
        res
          .filter((filter) => filter.archived == 0)
          .filter((data, index, array) => array.findIndex((f) => f.companhiaId === data.companhiaId) === index)
          .map((x) => {
            return {
              value: x.companhiaId,
              text: x.companhiaNome,
            };
          }),
      ),
    );
  });
}
function handleChangeFilter(id: number, event: any) {
  debugger;

  const idx = (filtersOptions.value as any[]).findIndex((f) => f.id == id);
  filtersOptions.value[idx].checked = event.target.checked;
}

onMounted(() => {
  acionGetUnidadesPorUsuario();
});

watch(
  () => props.title,
  () => {
    acionGetUnidadesPorUsuario();
  },
);

const __returned__ = { showModal, props, up, showModalProfile, showModalFIlter, optionsDropDown, searchlink, filtersOptions, filter, paddingLeft, filtersOptionsSelected, filtersUnits, handleClearFilter, handleLogout, handleFilter, handleCancelFilter, getFunction, acionGetUnidadesPorUsuario, handleChangeFilter, get AtomSubTitle() { return AtomSubTitle }, get AtomTitle() { return AtomTitle }, get FilterIcon() { return FilterIcon }, get SearchIcon2() { return SearchIcon2 }, get AtomNotification() { return AtomNotification }, get AtomCheckBox() { return AtomCheckBox }, get CleanFilter() { return CleanFilter }, get FiltersSearch() { return FiltersSearch }, get InfoIcon() { return InfoIcon }, get FilterFloatIcon() { return FilterFloatIcon }, get LogoutIcon() { return LogoutIcon }, get Authentication() { return Authentication }, get MoleculeModal() { return MoleculeModal }, get MoleculeSheetBottom() { return MoleculeSheetBottom }, FormLogin, get state() { return state }, get actions() { return actions }, AtomSelect, MoleculeModalAlert }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})