import { createCommentVNode as _createCommentVNode, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "d-inline-flex align-center justify-space-between",
  style: {"gap":"5px"}
}
const _hoisted_2 = { class: "d-flex justify-space-between w-100 ms-3 items-center" }
const _hoisted_3 = {
  class: "d-flex align-center",
  style: {"gap":"10px"}
}
const _hoisted_4 = { class: "d-flex align-center" }
const _hoisted_5 = { class: "ms-2" }
const _hoisted_6 = {
  class: "d-inline-flex align-center justify-space-between",
  style: {"gap":"5px"}
}
const _hoisted_7 = {
  class: "d-inline-flex align-center justify-space-between",
  style: {"gap":"5px"}
}
const _hoisted_8 = { class: "d-flex align-center" }
const _hoisted_9 = { class: "ms-2 mt-3" }
const _hoisted_10 = {
  class: "d-inline-flex align-center justify-space-between",
  style: {"gap":"5px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_switch = _resolveComponent("v-switch")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode(" <AtomTitle title=\"Serviços\" /> "),
    _createVNode($setup["MoleculeTitleSetings"], {
      addBtn: $setup.addNew,
      title: $setup.type == 'C' ? 'Cadastro de serviços' : $setup.type == 'E' ? 'Editando serviços' : 'Serviços',
      onHandleConfirm: $setup.replacement
    }, null, 8 /* PROPS */, ["addBtn", "title"]),
    (!$setup.addNew && !$setup.manageGroup)
      ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { cols: "12" }, {
              default: _withCtx(() => [
                _createVNode($setup["MoleculeTable"], {
                  onChangePerPage: _cache[1] || (_cache[1] = (ev) => ($setup.perPage = ev)),
                  onChangePage: _cache[2] || (_cache[2] = (ev) => ($setup.page = ev)),
                  onBlur: $setup.searching,
                  onHandleSort: $setup.sort,
                  filters: "",
                  headers: $setup.headers,
                  items: $setup.items,
                  totalItems: $setup.totalRows,
                  perPage: $setup.perPage,
                  page: $setup.page,
                  onItemClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.handleEdit($event, true))),
                  onItemClickDelete: _cache[4] || (_cache[4] = ($event: any) => ($setup.handleDelete($event)))
                }, {
                  actions: _withCtx(({ props }) => [
                    _createElementVNode("div", _hoisted_1, [
                      _createVNode($setup["AtomButton"], {
                        background: "white",
                        radius: "",
                        class: "pa-0",
                        onClick: _withModifiers(($event: any) => ($setup.handleEdit(props.row)), ["stop"])
                      }, {
                        default: _withCtx(() => [
                          _createVNode($setup["EditSquareIcon"])
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]),
                      _createVNode($setup["AtomButton"], {
                        background: "white",
                        radiusOutlined: "",
                        class: "pa-2",
                        onClick: _withModifiers(($event: any) => ($setup.handleGroup(props.row)), ["stop"])
                      }, {
                        default: _withCtx(() => [
                          _createVNode($setup["UsersIcon"])
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]),
                      (props.row.archived == 0)
                        ? (_openBlock(), _createBlock($setup["AtomButton"], {
                            key: 0,
                            background: "white",
                            radius: "",
                            class: "pa-0",
                            onClick: _withModifiers(($event: any) => ($setup.handleDelete(props.row)), ["stop"])
                          }, {
                            default: _withCtx(() => [
                              _createVNode($setup["DeleteIcon"])
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]))
                        : _createCommentVNode("v-if", true)
                    ])
                  ]),
                  areaFilter: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _cache[28] || (_cache[28] = _createElementVNode("div", null, [
                        _createCommentVNode(" <AtomButton background=\"white\" text=\"Manusear colunas\" outlined> </AtomButton> ")
                      ], -1 /* HOISTED */)),
                      _createElementVNode("div", _hoisted_3, [
                        _createCommentVNode(" <AtomButton background=\"white\" outlined>\n                <PaperUploadIcon />\n              </AtomButton>\n              <AtomButton background=\"white\" text=\"Excel\" outlined> </AtomButton>\n              <AtomButton prependIcon appendIcon background=\"white\" outlined text=\"Importar/Exportar\">\n                <template #appendIcon>\n                  <ArrowDown3Icon />\n                </template>\n              </AtomButton> "),
                        _createVNode($setup["AtomButton"], {
                          class: "ms-2",
                          text: "Novo Serviço",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => {
                  $setup.clear();
                  $setup.showModalUpload = true;
                })
                        })
                      ])
                    ])
                  ]),
                  "content-action": _withCtx(({ props }) => [
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          cols: "12",
                          class: "text-center"
                        }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomButton"], {
                              center: "text-center",
                              onClick: _withModifiers(($event: any) => ($setup.handleEdit(props, true)), ["stop"]),
                              class: "mx-auto w-100 rounded-pill",
                              text: 'Editar'
                            }, null, 8 /* PROPS */, ["onClick"])
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */),
                        _createVNode(_component_v_col, {
                          cols: "12",
                          class: "text-center"
                        }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomButton"], {
                              center: "text-center",
                              onClick: _withModifiers(($event: any) => ($setup.handleGroup(props)), ["stop"]),
                              class: "mx-auto w-100 rounded-pill",
                              text: 'Gerenciar grupos'
                            }, null, 8 /* PROPS */, ["onClick"])
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */),
                        _createVNode(_component_v_col, {
                          cols: "12",
                          class: "text-center"
                        }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomButton"], {
                              center: "text-center",
                              onClick: _withModifiers(($event: any) => ($setup.handleDelete(props)), ["stop"]),
                              class: "mx-auto w-100 rounded-pill",
                              text: "Excluir",
                              background: "gray-05"
                            }, null, 8 /* PROPS */, ["onClick"])
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["headers", "items", "totalItems", "perPage", "page"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    ($setup.addNew && !$setup.manageGroup)
      ? (_openBlock(), _createBlock(_component_v_container, { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { class: "my-3" }, {
                  default: _withCtx(() => _cache[29] || (_cache[29] = [
                    _createElementVNode("ul", null, [
                      _createElementVNode("li", { class: "text-gray-02" }, "Preencha todos os campos corretamente para cadastrar um novo serviço.")
                    ], -1 /* HOISTED */)
                  ])),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_v_row, { class: "text-sm" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomSelect"], {
                      width: "100%",
                      placeholder: "Selecione",
                      label: "Tipo",
                      modelValue: $setup.data.type,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.data.type) = $event)),
                      options: $setup.tipos
                    }, null, 8 /* PROPS */, ["modelValue", "options"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomSelect"], {
                      width: "100%",
                      placeholder: "Selecione",
                      label: "Grupos com esse serviço",
                      modelValue: $setup.data.grupoIntLista,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.data.grupoIntLista) = $event)),
                      multiple: "",
                      options: $setup.grupos
                    }, null, 8 /* PROPS */, ["modelValue", "options"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      width: "100%",
                      required: "",
                      msgError: "Campo obrigatório",
                      hasError: !$setup.data.nome,
                      placeholder: "Digite aqui",
                      label: "Nome do serviço",
                      modelValue: $setup.data.nome,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.data.nome) = $event))
                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      width: "100%",
                      required: "",
                      msgError: "Campo obrigatório",
                      hasError: !$setup.data.target,
                      placeholder: "Digite aqui",
                      label: "Destino",
                      modelValue: $setup.data.target,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($setup.data.target) = $event))
                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      width: "100%",
                      placeholder: "Digite aqui",
                      label: "Latitude",
                      modelValue: $setup.data.latitude,
                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (($setup.data.latitude) = $event))
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      width: "100%",
                      placeholder: "Digite aqui",
                      label: "Longitude",
                      modelValue: $setup.data.longitude,
                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (($setup.data.longitude) = $event))
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_v_row, { class: "mt-5" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, {
                  cols: "12",
                  class: "text-center"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomButton"], {
                      center: "text-center",
                      onClick: _cache[11] || (_cache[11] = ($event: any) => ($setup.handleAddOrUpdate())),
                      class: "mx-auto w-100 rounded-pill",
                      text: $setup.type == 'C' ? 'Concluir Cadastro' : 'Concluir edição'
                    }, null, 8 /* PROPS */, ["text"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, {
                  cols: "12",
                  class: "text-center"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomButton"], {
                      center: "text-center",
                      onClick: _cache[12] || (_cache[12] = ($event: any) => (($setup.addNew = false), ($setup.type = ''), ($setup.data = {}))),
                      class: "mx-auto w-100 rounded-pill",
                      text: "Voltar",
                      background: "gray-05"
                    })
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    ($setup.manageGroup)
      ? (_openBlock(), _createBlock(_component_v_container, { key: 2 }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "12"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode($setup["AtomSelect"], {
                        class: "mt-3",
                        width: "100%",
                        placeholder: "Selecione um ou mais grupos",
                        label: "Selecione um ou mais grupos",
                        modelValue: $setup.grupo,
                        "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => (($setup.grupo) = $event)),
                        options: $setup.grupos,
                        multiple: true
                      }, null, 8 /* PROPS */, ["modelValue", "options"]),
                      _createElementVNode("div", _hoisted_5, [
                        _createVNode($setup["AtomButton"], {
                          class: "px-2",
                          onClick: $setup.setGrupo
                        }, {
                          default: _withCtx(() => [
                            _createVNode($setup["PlusIcon"], { color: "white" })
                          ]),
                          _: 1 /* STABLE */
                        })
                      ])
                    ])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, { md: "12" }, {
                  default: _withCtx(() => [
                    _createVNode($setup["MoleculeTable"], {
                      headers: $setup.headersGrupo,
                      items: $setup.data.grupoDTOLista,
                      pagination: false
                    }, {
                      actions: _withCtx(({ props }) => [
                        _createElementVNode("div", _hoisted_6, [
                          _createVNode($setup["AtomButton"], {
                            background: "white",
                            radius: "",
                            class: "pa-0",
                            onClick: _withModifiers(($event: any) => ($setup.handleDeleteGrupo(props.row)), ["stop"])
                          }, {
                            default: _withCtx(() => [
                              _createVNode($setup["DeleteIcon"])
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
                        ])
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["headers", "items"])
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, {
                  cols: "12",
                  class: "text-center"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomButton"], {
                      center: "text-center",
                      onClick: $setup.handleSaveGrupo,
                      class: "mx-auto w-100 rounded-pill",
                      text: $setup.type == 'C' ? 'Concluir Cadastro' : 'Concluir Edição'
                    }, null, 8 /* PROPS */, ["text"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, {
                  cols: "12",
                  class: "text-center"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomButton"], {
                      center: "text-center",
                      onClick: _cache[14] || (_cache[14] = 
            () => {
              $setup.manageGroup = false;
              $setup.data = {};
            }
          ),
                      class: "mx-auto w-100 rounded-pill",
                      text: "Voltar",
                      background: "gray-05"
                    })
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    _createVNode($setup["MoleculeModal"], {
      width: "640px",
      onHandleConfirm: $setup.handleAddOrUpdate,
      onHandleCancel: _cache[22] || (_cache[22] = ($event: any) => ($setup.showModalUpload = false)),
      title: "Cadastro de serviço",
      subtitle: 'Preencha todos os campos corretamente para cadastrar um novo serviço',
      show: $setup.showModalUpload,
      "btn-close-text": "Cancelar",
      "btn-confirm-text": "Registrar"
    }, {
      body: _withCtx(() => [
        _createVNode(_component_v_row, { class: "text-sm" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { md: "6" }, {
              default: _withCtx(() => [
                _createVNode($setup["AtomSelect"], {
                  width: "100%",
                  placeholder: "Selecione um tipo",
                  label: "Selecione um tipo",
                  modelValue: $setup.data.type,
                  "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => (($setup.data.type) = $event)),
                  options: $setup.tipos
                }, null, 8 /* PROPS */, ["modelValue", "options"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_v_col, { md: "6" }, {
              default: _withCtx(() => [
                _createVNode($setup["AtomInput"], {
                  width: "100%",
                  required: "",
                  msgError: "Campo obrigatório",
                  hasError: !$setup.data.nome,
                  placeholder: "Nome do serviço",
                  label: "Nome do serviço",
                  modelValue: $setup.data.nome,
                  "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => (($setup.data.nome) = $event))
                }, null, 8 /* PROPS */, ["hasError", "modelValue"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_v_col, { md: "6" }, {
              default: _withCtx(() => [
                _createVNode($setup["AtomInput"], {
                  width: "100%",
                  required: "",
                  msgError: "Campo obrigatório",
                  hasError: !$setup.data.target,
                  placeholder: "Destino",
                  label: "Destino",
                  modelValue: $setup.data.target,
                  "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => (($setup.data.target) = $event))
                }, null, 8 /* PROPS */, ["hasError", "modelValue"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_v_col, { md: "6" }, {
              default: _withCtx(() => [
                _createVNode($setup["AtomInput"], {
                  width: "100%",
                  placeholder: "Latitude",
                  label: "Latitude",
                  modelValue: $setup.data.latitude,
                  "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => (($setup.data.latitude) = $event))
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_v_col, { md: "6" }, {
              default: _withCtx(() => [
                _createVNode($setup["AtomInput"], {
                  width: "100%",
                  placeholder: "Longitude",
                  label: "Longitude",
                  modelValue: $setup.data.longitude,
                  "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => (($setup.data.longitude) = $event))
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_v_col, { md: "6" }, {
              default: _withCtx(() => [
                _createVNode($setup["AtomSelect"], {
                  width: "100%",
                  placeholder: "Grupos com esse serviço",
                  label: "Grupos com esse serviço",
                  modelValue: $setup.data.grupoIntLista,
                  "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => (($setup.data.grupoIntLista) = $event)),
                  multiple: "",
                  options: $setup.grupos
                }, null, 8 /* PROPS */, ["modelValue", "options"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_v_col, { md: "6" }, {
              default: _withCtx(() => [
                _cache[30] || (_cache[30] = _createElementVNode("label", null, "Crítico", -1 /* HOISTED */)),
                _createVNode(_component_v_switch, {
                  modelValue: $setup.data.criticalService,
                  "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => (($setup.data.criticalService) = $event)),
                  color: "success",
                  value: true,
                  "hide-details": ""
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["show"]),
    _createVNode($setup["MoleculeModal"], {
      width: "640px",
      onHandleConfirm: _cache[24] || (_cache[24] = ($event: any) => ($setup.showModaGroups = false)),
      onHandleCancel: _cache[25] || (_cache[25] = ($event: any) => ($setup.showModaGroups = false)),
      title: "Gerenciar grupos",
      subtitle: 'Gerencia os grupos que esse serviço está inserido.',
      show: $setup.showModaGroups,
      "btn-close-text": "Fechar",
      "btn-confirm-text": "Salvar"
    }, {
      body: _withCtx(() => [
        _createVNode(_component_v_row, { class: "text-sm" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { md: "12" }, {
              default: _withCtx(() => [
                _createVNode($setup["AtomSelect"], {
                  multiple: "",
                  width: "100%",
                  placeholder: "Selecione um ou mais grupos",
                  label: "Selecione um ou mais grupos",
                  modelValue: $setup.grupos,
                  "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => (($setup.grupos) = $event)),
                  options: []
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_v_col, { md: "12" }, {
              default: _withCtx(() => [
                _createVNode($setup["MoleculeTable"], {
                  headers: $setup.headersGroups,
                  items: $setup.data.grupos,
                  pagination: false
                }, {
                  actions: _withCtx(({ props }) => [
                    _createElementVNode("div", _hoisted_7, [
                      _createVNode($setup["AtomButton"], {
                        background: "white",
                        radius: "",
                        class: "pa-0",
                        onClick: _withModifiers(($event: any) => ($setup.handleDelete(props.row)), ["stop"])
                      }, {
                        default: _withCtx(() => [
                          _createVNode($setup["DeleteIcon"])
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
                    ])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["headers", "items"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["show"]),
    _createVNode($setup["MoleculeModal"], {
      onHandleConfirm: $setup.handleSaveGrupo,
      onHandleCancel: _cache[27] || (_cache[27] = ($event: any) => ($setup.showModalGrupo = false)),
      title: "Gerenciar grupos",
      subtitle: 'Gerencie os grupos que esse serviço está inserido',
      show: $setup.showModalGrupo,
      "btn-close-text": "Cancelar",
      "btn-confirm-text": "Salvar"
    }, {
      body: _withCtx(() => [
        _createElementVNode("div", null, [
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "12",
                md: "12"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode($setup["AtomSelect"], {
                      width: "100%",
                      placeholder: "Selecione um ou mais grupos",
                      label: "Selecione um ou mais grupos",
                      modelValue: $setup.grupo,
                      "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => (($setup.grupo) = $event)),
                      options: $setup.grupos,
                      multiple: true
                    }, null, 8 /* PROPS */, ["modelValue", "options"]),
                    _createElementVNode("div", _hoisted_9, [
                      _createVNode($setup["AtomButton"], {
                        class: "px-2",
                        onClick: $setup.setGrupo
                      }, {
                        default: _withCtx(() => [
                          _createVNode($setup["PlusIcon"], { color: "white" })
                        ]),
                        _: 1 /* STABLE */
                      })
                    ])
                  ])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_col, { md: "12" }, {
                default: _withCtx(() => [
                  _createVNode($setup["MoleculeTable"], {
                    headers: $setup.headersGrupo,
                    items: $setup.data.grupoDTOLista,
                    pagination: false
                  }, {
                    actions: _withCtx(({ props }) => [
                      _createElementVNode("div", _hoisted_10, [
                        _createVNode($setup["AtomButton"], {
                          background: "white",
                          radius: "",
                          class: "pa-0",
                          onClick: _withModifiers(($event: any) => ($setup.handleDeleteGrupo(props.row)), ["stop"])
                        }, {
                          default: _withCtx(() => [
                            _createVNode($setup["DeleteIcon"])
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
                      ])
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["headers", "items"])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["show"])
  ], 64 /* STABLE_FRAGMENT */))
}