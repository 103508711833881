import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'


interface IProps {
  text: string;
  size?: string;
  classes?: string[];
  noMargin?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AtomSubTitle',
  props: {
    text: { type: String, required: true },
    size: { type: String, required: false, default: '14px' },
    classes: { type: Array, required: false },
    noMargin: { type: Boolean, required: false }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

_useCssVars(_ctx => ({
  "6862d346-size": (_ctx.size)
}))

const props = __props;

const __returned__ = { props }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})