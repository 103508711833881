import { openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["MoleculeCardSimple"], {
    title: $setup.props.title,
    onClickIconTitle: _cache[0] || (_cache[0] = ($event: any) => ($setup.emit('clickIconTitle')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        (_openBlock(), _createBlock($setup["VueHighcharts"], {
          key: `${$setup.props.title}-chart`,
          options: $setup.chartOptions,
          redrawOnUpdate: true,
          oneToOneUpdate: true,
          animateOnUpdate: true
        }, null, 8 /* PROPS */, ["options"]))
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title"]))
}