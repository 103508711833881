import { createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex align-center justify-center h-100 w-100"
}
const _hoisted_2 = { class: "login-wrapper h-100 w-100" }
const _hoisted_3 = { class: "d-flex form" }
const _hoisted_4 = { class: "bg-login w-100 w-100 d-flex flex-column justify-space-between" }
const _hoisted_5 = { class: "pa-8 dash-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!$setup.state.isMobile)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode($setup["FormConfirmEmail"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode($setup["AtomTitle"], {
                  classes: ['text-white'],
                  title: "Dashboard"
                }),
                _createVNode($setup["AtomSubTitle"], {
                  classes: ['text-white'],
                  class: "subtitle",
                  noMargin: "",
                  text: "Descrição simples e resumida da principal funcionalidade e/ou\n          melhoria da plataforma da GoConnect"
                }),
                _cache[0] || (_cache[0] = _createElementVNode("img", { src: "/assets/destac.png" }, null, -1 /* HOISTED */))
              ]),
              _cache[1] || (_cache[1] = _createElementVNode("div", {
                class: "area-dash",
                style: {"background-image":"url(/assets/dash.png)"}
              }, [
                _createCommentVNode(" <img class=\"w-100\" src=\"/assets/dash.png\" alt=\"dashboard\" /> ")
              ], -1 /* HOISTED */))
            ])
          ])
        ]))
      : _createCommentVNode("v-if", true),
    ($setup.state.isMobile)
      ? (_openBlock(), _createBlock(_component_v_row, {
          key: 1,
          class: "fill-height"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              cols: "12",
              class: "text-end login-icon"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["LoginPartTopIcon"], { class: "login-icon-right" })
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_v_col, {
              cols: "12",
              class: "d-flex justify-center align-center"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["FormConfirmEmail"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_v_col, {
              cols: "12",
              "align-self": "end",
              class: "text-start"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["LoginPartBottomIcon"], { class: "login-icon-left" })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}