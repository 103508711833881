import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, mergeProps as _mergeProps, createBlock as _createBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex justify-space-between align-center w-100"
}
const _hoisted_2 = {
  key: 1,
  class: "d-flex m-auto w-100 align-center justify-content-center"
}
const _hoisted_3 = { class: "d-flex justify-center align-center" }
const _hoisted_4 = { class: "area-search-link" }
const _hoisted_5 = { class: "filer-scroll" }
const _hoisted_6 = { class: "area-search-link d-flex justify-space-between" }
const _hoisted_7 = { class: "d-flex align-center" }
const _hoisted_8 = {
  key: 0,
  class: "filer-scroll"
}
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "d-flex justify-center mb-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_app_bar_nav_icon = _resolveComponent("v-app-bar-nav-icon")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_app_bar, {
      prominent: "",
      color: $setup.state.isMobile ? '' : 'white',
      style: _normalizeStyle(`padding: 29px 72px; padding-left: ${$setup.paddingLeft};`),
      class: _normalizeClass(["m-auto bg-surface", { 'bg-menu': $setup.state.isMobile }])
    }, {
      default: _withCtx(() => [
        ($setup.state.isMobile)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_v_app_bar_nav_icon, {
                color: "white",
                onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.actions.handleToggleMenu()))
              }),
              _cache[11] || (_cache[11] = _createElementVNode("p", null, [
                _createElementVNode("img", {
                  src: "/assets/logo-mob.png",
                  alt: "logo"
                })
              ], -1 /* HOISTED */)),
              _createVNode($setup["AtomNotification"])
            ]))
          : _createCommentVNode("v-if", true),
        (!$setup.state.isMobile)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode($setup["AtomTitle"], {
                  class: "mx-5",
                  noMargin: "",
                  title: $setup.props.title ? $setup.props.title : ''
                }, null, 8 /* PROPS */, ["title"]),
                _createCommentVNode(" <AtomToogleButton class=\"px-0 py-0\" v-if=\"title == 'Dashboard'\" :buttons=\"buttonsDashboard\"></AtomToogleButton>\n        <AtomSelect :options=\"[]\" class=\"rounded ms-5 c-select\"> </AtomSelect>"),
                _createCommentVNode(" <AtomToogleButton class=\"px-0 py-0\" v-if=\"title == 'Dashboard'\" :buttons=\"buttonsDashboard\"></AtomToogleButton> ")
              ]),
              _createVNode(_component_v_spacer),
              _createCommentVNode(" <v-menu\n       \n        :close-on-content-click=\"false\"\n      >\n        <template v-slot:activator=\"{ props }\">\n          <v-btn class=\"bg-white mb-5\" variant=\"text\" v-bind=\"props\">\n            \n          </v-btn>\n        </template>\n        <v-list :min-width=\"200\">\n          <v-list-item>\n            <AtomAccordion :initialShow=\"true\" title=\"Filtros de Empresas\">\n              <AtomCheckBox\n                v-for=\"(opt, index) in filtersOptions\"\n                :class=\"'deep-purple'\"\n                :key=\"String(opt.id)\"\n                :label=\"opt.nome\"\n                :checked=\"opt.checked\"\n                @change=\"handleChangeFilter(index, $event)\"\n              />\n            </AtomAccordion>\n          </v-list-item>\n        </v-list>\n      </v-menu> "),
              ($props.title == 'Dashboard' || $props.title == 'Gestão financeira' || $props.title == 'Análises Gerais')
                ? (_openBlock(), _createBlock(_component_v_btn, _mergeProps({
                    key: 0,
                    variant: "text"
                  }, $setup.props, {
                    icon: "mdi-filter",
                    class: "btn-notification position-relative",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.showModalFIlter = true)),
                    title: ""
                  }), {
                    default: _withCtx(() => [
                      _createVNode(_component_v_tooltip, {
                        activator: "parent",
                        location: "bottom"
                      }, {
                        default: _withCtx(() => _cache[12] || (_cache[12] = [
                          _createTextVNode("Filtros")
                        ])),
                        _: 1 /* STABLE */
                      }),
                      _createVNode($setup["FilterIcon"], { color: "var(--primary-nav)" })
                    ]),
                    _: 1 /* STABLE */
                  }, 16 /* FULL_PROPS */))
                : _createCommentVNode("v-if", true),
              _createVNode($setup["AtomNotification"]),
              _createCommentVNode(" <v-divider class=\"mx-5\" vertical></v-divider> "),
              _createVNode(_component_v_avatar, _mergeProps($setup.props, {
                color: "grey",
                size: "56",
                rounded: "lg",
                class: "me-5 cursor-pointer",
                onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.showModalProfile = true)),
                title: ""
              }), {
                default: _withCtx(() => [
                  _createVNode(_component_v_tooltip, {
                    activator: "parent",
                    location: "bottom"
                  }, {
                    default: _withCtx(() => _cache[13] || (_cache[13] = [
                      _createTextVNode("Editar perfil")
                    ])),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_v_img, {
                    cover: "",
                    src: $setup.Authentication.getters.getUser.value.fotoBase64
                  }, null, 8 /* PROPS */, ["src"])
                ]),
                _: 1 /* STABLE */
              }, 16 /* FULL_PROPS */),
              _createElementVNode("div", null, [
                _createVNode($setup["AtomTitle"], {
                  classes: ['text-sm'],
                  noMargin: "",
                  title: `${$setup.Authentication.state.nome}`
                }, null, 8 /* PROPS */, ["title"]),
                _createVNode($setup["AtomSubTitle"], {
                  classes: ['text-gray-01 text-xs'],
                  noMargin: "",
                  text: String($setup.getFunction())
                }, null, 8 /* PROPS */, ["text"])
              ]),
              _createElementVNode("button", {
                onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.showModal = true)),
                class: "ms-8 d-flex align-center"
              }, [
                _createVNode(_component_v_tooltip, {
                  activator: "parent",
                  location: "bottom"
                }, {
                  default: _withCtx(() => _cache[14] || (_cache[14] = [
                    _createTextVNode("Sair")
                  ])),
                  _: 1 /* STABLE */
                }),
                _createVNode($setup["LogoutIcon"], { class: "mx-5" })
              ])
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["color", "style", "class"]),
    _createVNode($setup["MoleculeModal"], {
      "padding-y": "32px",
      width: "450px",
      title: "Logout",
      showHeader: false,
      hideFooter: true,
      hideHeader: true,
      persistent: false,
      show: $setup.showModalProfile,
      "btn-close-text": "Cancelar",
      onHandleCancel: _cache[5] || (_cache[5] = ($event: any) => ($setup.showModalProfile = false))
    }, {
      body: _withCtx(() => [
        _createVNode($setup["FormLogin"], {
          onClose: _cache[4] || (_cache[4] = ($event: any) => ($setup.showModalProfile = false))
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["show"]),
    _createVNode($setup["MoleculeModal"], {
      width: "960px",
      "body-scrool": false,
      onHandleConfirm: $setup.handleFilter,
      onHandleCancel: $setup.handleCancelFilter,
      title: "Filtros",
      show: $setup.showModalFIlter,
      "btn-close-text": 'Voltar',
      "btn-confirm-text": 'Filtrar',
      hideDividerHeader: "",
      paddingY: "15px"
    }, {
      body: _withCtx(() => [
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { cols: "12" }, {
              default: _withCtx(() => [
                _createVNode($setup["AtomSelect"], {
                  modelValue: $setup.filter.empresa,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.filter.empresa) = $event)),
                  width: "408px",
                  options: $setup.optionsDropDown,
                  label: "Empresa",
                  rounded: "lg",
                  class: "m-0 c-select text-dark",
                  multiple: ""
                }, null, 8 /* PROPS */, ["modelValue", "options"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_v_col, { class: "border-e" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode($setup["SearchIcon2"], { color: "var(--primary-nav)" }),
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.searchlink) = $event)),
                    placeholder: "Pesquise a unidade",
                    class: "w-100"
                  }, null, 512 /* NEED_PATCH */), [
                    [_vModelText, $setup.searchlink]
                  ])
                ]),
                _createElementVNode("div", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.filtersUnits, (opt) => {
                    return (_openBlock(), _createBlock($setup["AtomCheckBox"], {
                      class: _normalizeClass('deep-purple'),
                      key: String(opt.id),
                      label: opt.nome,
                      checked: opt.checked,
                      onChange: ($event: any) => ($setup.handleChangeFilter(opt.id, $event))
                    }, null, 8 /* PROPS */, ["label", "checked", "onChange"]))
                  }), 128 /* KEYED_FRAGMENT */))
                ])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_v_col, null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _cache[15] || (_cache[15] = _createElementVNode("p", { class: "mr-2" }, "Unidades selecionados", -1 /* HOISTED */)),
                    _withDirectives(_createVNode($setup["InfoIcon"], { color: "var(--primary-nav)" }, null, 512 /* NEED_PATCH */), [
                      [_directive_tooltip, 'Máximo de 5 Unidades selecionadas']
                    ])
                  ]),
                  _createElementVNode("button", {
                    class: "d-flex align-center",
                    onClick: $setup.handleClearFilter
                  }, [
                    _createVNode($setup["CleanFilter"], { color: "var(--primary-nav)" }),
                    _createElementVNode("p", {
                      class: "ml-2",
                      style: _normalizeStyle(`color: var(--primary-nav);${$setup.filtersOptionsSelected.length > 0 ? '' : 'opacity: 0.1'}`)
                    }, " Limpar dados ", 4 /* STYLE */)
                  ])
                ]),
                _createVNode(_component_v_col, { class: "border-e" }, {
                  default: _withCtx(() => [
                    ($setup.filtersOptionsSelected.length > 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.filtersOptionsSelected, (opt) => {
                            return (_openBlock(), _createBlock($setup["AtomCheckBox"], {
                              class: _normalizeClass('deep-purple'),
                              key: String(opt.id),
                              label: opt.nome,
                              checked: opt.checked,
                              disabled: true
                            }, null, 8 /* PROPS */, ["label", "checked"]))
                          }), 128 /* KEYED_FRAGMENT */))
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                          _createElementVNode("div", _hoisted_10, [
                            _createVNode($setup["FiltersSearch"], { color: "var(--primary-nav)" })
                          ]),
                          _cache[16] || (_cache[16] = _createElementVNode("p", {
                            style: {"color":"#b2bbc7"},
                            class: "text-center"
                          }, "As unidades selecionadas irão aparecer aqui.", -1 /* HOISTED */))
                        ]))
                  ]),
                  _: 1 /* STABLE */
                }),
                _createCommentVNode(" <div class=\"d-flex justify-center align-center filer-scroll h-100\">\n            <template>\n              <AtomCheckBox\n                v-for=\"opt in filtersOptionsSelected\"\n                :key=\"`${opt.id}-id`\"\n                :label=\"opt.nome\"\n                class=\"flex-column\"\n              />\n            </template>\n          </div> ")
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["show"]),
    (($props.title == 'Dashboard' || $props.title == 'Informações financeiras' || $props.title == 'Análises Gerais') && $setup.state.isMobile)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "filter text-white",
          onClick: _cache[8] || (_cache[8] = ($event: any) => ($setup.up = !$setup.up))
        }, [
          _createElementVNode("a", null, [
            _createVNode($setup["FilterFloatIcon"])
          ])
        ]))
      : _createCommentVNode("v-if", true),
    _createVNode($setup["MoleculeSheetBottom"], {
      title: "Filtros de Empresas",
      subtitle: "Selecione as empresas que deseja gerenciar",
      sheet: $setup.up,
      "onUpdate:sheet": _cache[9] || (_cache[9] = ($event: any) => ($setup.up = $event))
    }, {
      content: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.filtersOptionsSelected, (opt, index) => {
          return (_openBlock(), _createBlock($setup["AtomCheckBox"], {
            class: _normalizeClass('deep-purple'),
            key: String(opt.id),
            label: opt.nome,
            checked: opt.checked,
            onChange: ($event: any) => ($setup.handleChangeFilter(index, $event))
          }, null, 8 /* PROPS */, ["label", "checked", "onChange"]))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["sheet"]),
    _createVNode($setup["MoleculeModalAlert"], {
      "show-alert-confirm": $setup.showModal,
      icon: 'logoutModalIcon',
      text: "Certeza que deseja sair?",
      onCancel: _cache[10] || (_cache[10] = ($event: any) => ($setup.showModal = false)),
      onConfirm: $setup.handleLogout
    }, null, 8 /* PROPS */, ["show-alert-confirm"])
  ], 64 /* STABLE_FRAGMENT */))
}