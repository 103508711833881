import { defineComponent as _defineComponent } from 'vue'
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { Authentication, Preloader } from '@/store';
import {
  AtomTitle,
  AtomInput,
  MessageIcon,
  LockIcon,
  ShowIcon,
  AtomSubTitle,
  LoginIcon,
  lockLoginIcon,
  ProfileIconLogin,
  AtomButton,
} from '@/components/atoms';
import { InputType } from '@/types';
import { Credential } from '../../../core/models';
import { state } from '@/store/modules/layout';
import swal from 'sweetalert2';
import { AxiosError } from 'axios';

export default /*@__PURE__*/_defineComponent({
  __name: 'FormLogin',
  setup(__props, { expose: __expose }) {
  __expose();

const router = useRouter();
const email = ref<string>('');

const inputTypePassword = ref<InputType>('password');
const password = ref<string>('');

const listError = ref<string[]>([]);

const version = ref<string>('');
onMounted(() => {
  fetch('version')
    .then((response) => response.text())
    .then((csvString) => {
      version.value = csvString;
    });
});
function handleLogin() {
  listError.value = [];

  if (!password.value) listError.value.push('password');
  if (!email.value) listError.value.push('email');

  if (listError.value.length > 0) return;

  const auth: Credential = {
    acessToken: process.env.VUE_APP_AUTH_TOKEN,
    email: email.value,
    password: password.value,
  };
  Preloader.actions.time(true);
  Authentication.actions
    .login(auth)
    .then(() => {
      setTimeout(() => {
        router.push({ name: 'Home' });
      }, 2000);
    })
    .catch((f: AxiosError) => {
      if (f.response?.status == 401) {
        swal.fire({
          title: 'Ops.',
          text: 'Usuário ou senha inválidos',
          icon: 'warning',
        });
      } else {
        swal.fire({
          title: 'Ops...',
          text: 'Erro inesperado no sistema, favor entrar em contato com o suporte',
          icon: 'error',
        });
      }
    })
    .finally(() => {
      Preloader.actions.time(false);
    });
}

const __returned__ = { router, email, inputTypePassword, password, listError, version, handleLogin, get AtomTitle() { return AtomTitle }, get AtomInput() { return AtomInput }, get MessageIcon() { return MessageIcon }, get LockIcon() { return LockIcon }, get ShowIcon() { return ShowIcon }, get AtomSubTitle() { return AtomSubTitle }, get LoginIcon() { return LoginIcon }, get lockLoginIcon() { return lockLoginIcon }, get ProfileIconLogin() { return ProfileIconLogin }, get AtomButton() { return AtomButton }, get state() { return state } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})