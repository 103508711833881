import { defineComponent as _defineComponent } from 'vue'
import { ref } from 'vue';
import { MoleculeModal } from '.';
import * as comps from '@/components/atoms';

interface IProps {
  icon?: string;
  text?: string;
  width?: string;
  description?: string;
  iconColor?: string;
  showAlertConfirm: boolean;
  isText?: boolean;
  noBackButton?: boolean;
  label?: string;
  textButton?: string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'MoleculeModalAlertRecoverPassword',
  props: {
    icon: { type: String, required: false },
    text: { type: String, required: false },
    width: { type: String, required: false, default: '320px' },
    description: { type: String, required: false },
    iconColor: { type: String, required: false },
    showAlertConfirm: { type: Boolean, required: true },
    isText: { type: Boolean, required: false, default: false },
    noBackButton: { type: Boolean, required: false },
    label: { type: String, required: false },
    textButton: { type: String, required: false, default: 'Sim' }
  },
  emits: ['cancel', 'confirm'],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;
const reason = ref<string>('');
const emit = __emit;

function handleCancel() {
  emit('cancel');
}

function handleConfirm() {
  emit('confirm', reason);
}

const __returned__ = { props, reason, emit, handleCancel, handleConfirm, get MoleculeModal() { return MoleculeModal }, get comps() { return comps } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})