import { defineComponent as _defineComponent } from 'vue'
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { Preloader } from '@/store';
import {
  AtomTitle,
  AtomInput,
  AtomSubTitle,
  LoginIcon,
  ShowIcon,
  LockIcon,
  AtomButton,
} from '@/components/atoms';
// import { Credential } from '../../../core/models';
import { state } from '@/store/modules/layout';
import { InputType } from '@/types';
import { MoleculeModalAlertRecoverPassword } from '@/components/molecules';

// import swal from 'sweetalert2';
// import { AxiosError } from 'axios';

export default /*@__PURE__*/_defineComponent({
  __name: 'FormResetPassword',
  setup(__props, { expose: __expose }) {
  __expose();

const router = useRouter();
// const route = useRoute();
const listError = ref<string[]>([]);
const inputTypePassword = ref<InputType>('password');
const inputTypePasswordConfirm = ref<InputType>('password');
const password = ref('');
const passwordConfirm = ref('');
const showModalAlert = ref<boolean>(false);
const loading = ref<boolean>(false);

const version = ref<string>('');
function handleFinsh() {
  router.push({ name: 'Login' });
}
onMounted(() => {
  fetch('/version')
    .then((response) => response.text())
    .then((csvString) => {
      version.value = csvString;
    });
});

function handleErrorBlur(field: string) {
  listError.value = listError.value.filter((item) => item !== field);
  if (field === 'password' && !password.value) listError.value.push('password');
  if (field === 'passwordConfirm') {
    if (!passwordConfirm.value) listError.value.push('passwordConfirm');
  }
  if (password.value !== passwordConfirm.value) listError.value.push('passwordConfirm');
  else listError.value = listError.value.filter((item) => item !== 'passwordConfirm');
}

function handleCheckPassword() {
  if (password.value !== passwordConfirm.value) listError.value.push('passwordConfirm');
}

function handleSetError() {
  listError.value = [];
  if (!password.value) listError.value.push('password');
  if (!passwordConfirm.value) listError.value.push('passwordConfirm');
  if (password.value !== passwordConfirm.value) listError.value.push('passwordConfirm');
}
function handleChangePassword() {
  listError.value = [];

  handleSetError();

  if (listError.value.length > 0) return;

  loading.value = true;

  setTimeout(() => {
    showModalAlert.value = true;
    loading.value = false;
  }, 2000);


  // const auth: Credential = {
  //   acessToken: String(route.params.acessToken),
  //   email: email.value,
  // };
  Preloader.actions.time(true);
  // Authentication.actions
  //   .login(auth)
  //   .then(() => {  
  //       setTimeout(() => {
  //     router.push({ name: 'Home' });
  //   }, 2000);
  //   })
  //   .catch((f: AxiosError) => {
  //     if (f.response.status == 401) {
  //       swal.fire({
  //         title: 'Ops.',
  //         text: 'Usuário ou senha inválidos',
  //         icon: 'warning',
  //       });
  //     } else {
  //       swal.fire({
  //         title: 'Ops...',
  //         text: 'Erro inesperado no sistema, favor entrar em contato com o suporte',
  //         icon: 'error',
  //       });
  //     }
  //   })
  //   .finally(() => {
  //     Preloader.actions.time(false);
          // loading.value = false;
  //   });
}

const __returned__ = { router, listError, inputTypePassword, inputTypePasswordConfirm, password, passwordConfirm, showModalAlert, loading, version, handleFinsh, handleErrorBlur, handleCheckPassword, handleSetError, handleChangePassword, get AtomTitle() { return AtomTitle }, get AtomInput() { return AtomInput }, get AtomSubTitle() { return AtomSubTitle }, get LoginIcon() { return LoginIcon }, get ShowIcon() { return ShowIcon }, get LockIcon() { return LockIcon }, get AtomButton() { return AtomButton }, get state() { return state }, get MoleculeModalAlertRecoverPassword() { return MoleculeModalAlertRecoverPassword } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})