import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, { cols: "6" }, {
          default: _withCtx(() => [
            _createVNode($setup["MoleculeTableSimple"], {
              columns: $setup.columnsLink,
              rows: $setup.monitoramento,
              onClickRow: $setup.handleClickRow
            }, null, 8 /* PROPS */, ["columns", "rows"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_v_col, { cols: "6" }, {
          default: _withCtx(() => [
            _createVNode($setup["MoleculeTableSimple"], {
              columns: $setup.columnsService,
              rows: $setup.rowsServices
            }, null, 8 /* PROPS */, ["columns", "rows"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_v_col, { cols: "6" }, {
          default: _withCtx(() => [
            _createVNode($setup["OrganismsQualityChart"], {
              data: $setup.dataQualidade.series,
              title: $setup.dataQualidade.title,
              tooltip: $setup.dataQualidade.tooltip,
              onClickIconTitle: $setup.handleQualityChart
            }, null, 8 /* PROPS */, ["data", "title", "tooltip"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_v_col, { cols: "6" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, { class: "h-100" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, {
                  cols: "6",
                  class: "h-100"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["OrganismsProgressCircle"], {
                      value: $setup.progressQualidade.value,
                      title: $setup.progressQualidade.title,
                      tooltip: $setup.progressQualidade.tooltip,
                      "not-percent": $setup.progressQualidade.notPercentual,
                      onClickIconTitle: $setup.handleMonitorar
                    }, null, 8 /* PROPS */, ["value", "title", "tooltip", "not-percent"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, {
                  cols: "6",
                  class: "h-100"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["OrganismsEconomy"], {
                      value: $setup.economy.data,
                      title: $setup.economy.title,
                      tooltip: $setup.economy.tooltip,
                      onClickIconTitle: $setup.handleEconomy
                    }, null, 8 /* PROPS */, ["value", "title", "tooltip"])
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }),
    _createCommentVNode(" <button @click=\"Notification.incrementNotification\">Teste nova notificação</button> "),
    _createCommentVNode(" <button @click=\"Notification.setNotification({text: `Novo notificação ${Notification.getNotifications.value.length + 1}`, id: String(Notification.getNotifications.value.length + 1)})\">Teste nova notificação</button> "),
    _createCommentVNode(" <MoleculeTabs v-model=\"tab\" :textTooltip=\"true\" :tabs=\"tabs\" v-if=\"!state.isMobile\" />\n    <MoleculeTabWindow v-model=\"tab\" :class=\"{ border: !state.isMobile }\">\n      <MoleculeTabItem value=\"Observabilidade\">\n        <OverView @realtime=\"dadosRealtime = $event\" @redirect=\"tab = 'realtime'\" :roda=\"rodarMonitor\" />\n      </MoleculeTabItem>\n      <MoleculeTabItem value=\"financeiras\">\n        <InformacoesFinanceiras />\n      </MoleculeTabItem>\n      <MoleculeTabItem value=\"realtime\">\n        <MonitorRealtime @redirect=\"tab = 'Observabilidade'\" :dadosRealtime=\"dadosRealtime\" @load=\"handleLoadMonitor\" />\n      </MoleculeTabItem>\n    </MoleculeTabWindow> "),
    _createVNode($setup["MoleculeModal"], {
      width: "1500px",
      bodyScrool: false,
      title: `${$setup.itemDetail.nomeLink}`,
      show: $setup.showModal,
      hideFooter: true,
      onHandleCancel: _cache[8] || (_cache[8] = ($event: any) => ($setup.showModal = false))
    }, {
      step: _withCtx(() => [
        _createVNode($setup["AtomBadge"], {
          color: $setup.fnColorPercentual($setup.itemDetail) ?? 'var(--color-success)',
          bgColor: $setup.fnBgColorPercebtual($setup.itemDetail) ?? '#00B88D10',
          text: $setup.itemDetail.percentual,
          class: "ml-4"
        }, null, 8 /* PROPS */, ["color", "bgColor", "text"])
      ]),
      body: _withCtx(() => [
        _createVNode(_component_v_container, { class: "text-sm px-0" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "4"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      width: "100%",
                      disabled: true,
                      placeholder: "Usuário da máquina",
                      label: "Usuário da máquina",
                      modelValue: $setup.itemDetail.userName,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.itemDetail.userName) = $event))
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "4"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      width: "100%",
                      disabled: true,
                      placeholder: "Host",
                      modelValue: $setup.itemDetail.hostName,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.itemDetail.hostName) = $event)),
                      modelModifiers: { number: true },
                      label: "Host"
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "4"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      width: "100%",
                      disabled: true,
                      placeholder: "Versão do coletor",
                      modelValue: $setup.itemDetail.versaoColetor,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.itemDetail.versaoColetor) = $event)),
                      modelModifiers: { number: true },
                      label: "Versão do coletor"
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "4"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      width: "100%",
                      disabled: true,
                      placeholder: "Dados de VOIP",
                      modelValue: $setup.itemDetail.qualidadeVoz,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.itemDetail.qualidadeVoz) = $event)),
                      modelModifiers: { number: true },
                      label: "Dados de VOIP",
                      prependIcon: ""
                    }, {
                      prependIcon: _withCtx(() => [
                        _createElementVNode("span", {
                          class: "point",
                          style: _normalizeStyle(`background: ${$setup.fnBgColor($setup.itemDetail.qualidadeVoz)}`)
                        }, null, 4 /* STYLE */)
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "4"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      width: "100%",
                      disabled: true,
                      placeholder: "Dados de vídeo",
                      modelValue: $setup.itemDetail.qualidadeVideo,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.itemDetail.qualidadeVideo) = $event)),
                      modelModifiers: { number: true },
                      label: "Dados de vídeo",
                      prependIcon: ""
                    }, {
                      prependIcon: _withCtx(() => [
                        _createElementVNode("span", {
                          class: "point",
                          style: _normalizeStyle(`background: ${$setup.fnBgColor($setup.itemDetail.qualidadeVideo)}`)
                        }, null, 4 /* STYLE */)
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_v_divider, { class: "my-10" }),
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "4"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      width: "100%",
                      disabled: true,
                      placeholder: "Jitter atual",
                      label: "Jitter atual",
                      modelValue: $setup.itemDetail.jitter,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.itemDetail.jitter) = $event))
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "4"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      width: "100%",
                      disabled: true,
                      placeholder: "Latência atual",
                      label: "Latência atual",
                      modelValue: $setup.itemDetail.latencia,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.itemDetail.latencia) = $event))
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "4"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      width: "100%",
                      disabled: true,
                      placeholder: "Perda de Pacote atual",
                      label: "Perda de Pacote atual",
                      modelValue: $setup.itemDetail.perdaPacote,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.itemDetail.perdaPacote) = $event))
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }),
            _createCommentVNode(" <v-row>\n            <v-col v-for=\"chart in itemDetail.charts\" :key=\"chart.title\" cols=\"12\" md=\"4\">\n              <OrganismsDetailChart :data=\"chart.series\" :title=\"chart.title\" @clickIconTitle=\"handleQualityChart\">\n              </OrganismsDetailChart>\n            </v-col>\n          </v-row> ")
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["title", "show"])
  ]))
}