import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "d-flex justify-center" }
const _hoisted_2 = { class: "text-center item-text mt-8 mb-4" }
const _hoisted_3 = {
  key: 0,
  class: "text-gray-01 text-center mb-6"
}
const _hoisted_4 = {
  key: 1,
  class: "text-black-light-2 text-center my-6"
}
const _hoisted_5 = { class: "d-flex justify-center area-btns" }
const _hoisted_6 = { class: "text-white text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_textarea = _resolveComponent("v-textarea")!

  return (_openBlock(), _createBlock($setup["MoleculeModal"], {
    "padding-y": "32px",
    width: $props.width,
    onHandleCancel: $setup.handleCancel,
    title: "Logout",
    showHeader: false,
    hideFooter: true,
    hideHeader: true,
    persistent: true,
    show: $props.showAlertConfirm,
    "btn-close-text": "Cancelar"
  }, {
    body: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_1, [
          ($setup.props.icon)
            ? (_openBlock(), _createBlock(_resolveDynamicComponent($setup.comps[$setup.props.icon]), {
                key: 0,
                color: $setup.props.iconColor
              }, null, 8 /* PROPS */, ["color"]))
            : _createCommentVNode("v-if", true)
        ]),
        _createElementVNode("p", _hoisted_2, [
          _createElementVNode("strong", null, _toDisplayString($setup.props.text), 1 /* TEXT */)
        ]),
        ($setup.props.description)
          ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString($setup.props.description), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        ($setup.props.isText)
          ? (_openBlock(), _createElementBlock("p", _hoisted_4, [
              _createVNode(_component_v_textarea, {
                label: $setup.props.label,
                modelValue: $setup.reason,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.reason) = $event))
              }, null, 8 /* PROPS */, ["label", "modelValue"])
            ]))
          : _createCommentVNode("v-if", true),
        _createElementVNode("div", _hoisted_5, [
          (!$setup.props.noBackButton)
            ? (_openBlock(), _createBlock($setup["comps"].AtomButton, {
                key: 0,
                background: "transparent",
                outlined: "",
                onClick: $setup.handleCancel,
                class: "w-100 text-center",
                density: "default",
                "base-color": "var(--primary-nav-10)",
                size: "56",
                variant: "flat"
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createElementVNode("span", { class: "ms-5" }, " Voltar ", -1 /* HOISTED */)
                ])),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true),
          _createVNode($setup["comps"].AtomButton, {
            onClick: $setup.handleConfirm,
            class: "w-100 justify-center",
            disabled: $setup.props.isText && !$setup.reason
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_6, _toDisplayString($props.textButton), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["disabled"])
        ])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["width", "show"]))
}