import { defineComponent as _defineComponent } from 'vue'
import { AtomSubTitle, AtomTitle, LoginPartBottomIcon, LoginPartTopIcon } from '@/components/atoms';
import { FormConfirmEmail } from './components';
import { state } from '../../store/modules/layout';

export default /*@__PURE__*/_defineComponent({
  __name: 'EmailRecoverPage',
  setup(__props, { expose: __expose }) {
  __expose();


const __returned__ = { get AtomSubTitle() { return AtomSubTitle }, get AtomTitle() { return AtomTitle }, get LoginPartBottomIcon() { return LoginPartBottomIcon }, get LoginPartTopIcon() { return LoginPartTopIcon }, get FormConfirmEmail() { return FormConfirmEmail }, get state() { return state } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})