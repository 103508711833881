import { createElementVNode as _createElementVNode, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, withCtx as _withCtx, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "mb-5" }
const _hoisted_2 = { class: "text-sm text-center" }
const _hoisted_3 = {
  key: 0,
  class: "text-forgot cursor-pointer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lockLoginIcon = _resolveComponent("lockLoginIcon")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("form", {
      class: "area-form",
      onSubmit: _withModifiers($setup.handleChangePassword, ["prevent"])
    }, [
      _cache[8] || (_cache[8] = _createElementVNode("img", {
        src: "/assets/GoConnect-logo.svg",
        class: "mb-12",
        alt: "logo"
      }, null, -1 /* HOISTED */)),
      _createVNode($setup["AtomTitle"], {
        size: "32px",
        title: 'Redefina sua senha'
      }),
      _createVNode($setup["AtomSubTitle"], {
        size: "16px",
        text: 'Insira a nova senha desejada.'
      
      }),
      _createElementVNode("div", _hoisted_1, [
        _createVNode($setup["AtomInput"], {
          required: "",
          type: $setup.inputTypePassword,
          hasError: $setup.listError.includes('password'),
          msgError: "Campo obrigatório.",
          appendIcon: "",
          prependIcon: "",
          noBgAppendIcon: "",
          onKeydown: _withKeys($setup.handleChangePassword, ["enter"]),
          onBlur: _cache[1] || (_cache[1] = ($event: any) => ($setup.handleErrorBlur('password'))),
          label: !$setup.state.isMobile ? 'Senha*' : 'Senha',
          modelValue: $setup.password,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.password) = $event))
        }, {
          appendIcon: _withCtx(() => [
            _createVNode($setup["ShowIcon"], {
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => ($setup.inputTypePassword = $setup.inputTypePassword === 'password' ? 'text' : 'password'), ["prevent"])),
              color: $setup.listError.includes('password') ? 'var(--color-error)' : ''
            }, null, 8 /* PROPS */, ["color"])
          ]),
          prependIcon: _withCtx(() => [
            (!$setup.state.isMobile)
              ? (_openBlock(), _createBlock($setup["LockIcon"], { key: 0 }))
              : (_openBlock(), _createBlock(_component_lockLoginIcon, { key: 1 }))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["type", "hasError", "label", "modelValue"])
      ]),
      _createVNode($setup["AtomInput"], {
        required: "",
        type: $setup.inputTypePasswordConfirm,
        hasError: $setup.listError.includes('passwordConfirm'),
        msgError: "Senhas não correspondem, tente novamente.",
        appendIcon: "",
        prependIcon: "",
        noBgAppendIcon: "",
        onKeydown: _withKeys($setup.handleChangePassword, ["enter"]),
        onBlur: _cache[4] || (_cache[4] = ($event: any) => ($setup.handleErrorBlur('passwordConfirm'))),
        onInput: $setup.handleCheckPassword,
        label: 'Confirmar nova senha*',
        modelValue: $setup.passwordConfirm,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.passwordConfirm) = $event))
      }, {
        appendIcon: _withCtx(() => [
          _createVNode($setup["ShowIcon"], {
            onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => ($setup.inputTypePasswordConfirm = $setup.inputTypePasswordConfirm === 'password' ? 'text' : 'password'), ["prevent"])),
            color: $setup.listError.includes('password') ? 'var(--color-error)' : ''
          }, null, 8 /* PROPS */, ["color"])
        ]),
        prependIcon: _withCtx(() => [
          (!$setup.state.isMobile)
            ? (_openBlock(), _createBlock($setup["LockIcon"], { key: 0 }))
            : (_openBlock(), _createBlock(_component_lockLoginIcon, { key: 1 }))
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["type", "hasError", "modelValue"]),
      (!$setup.state.isMobile)
        ? (_openBlock(), _createBlock(_component_v_btn, {
            key: 0,
            loading: $setup.loading,
            onClick: $setup.handleChangePassword,
            variant: "flat",
            color: "primary",
            class: "w-100 my-5 mt-9"
          }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createTextVNode(" Enviar ")
            ])),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["loading"]))
        : (_openBlock(), _createBlock($setup["AtomButton"], {
            key: 1,
            loading: $setup.loading,
            center: "text-center",
            IconText: "",
            onClick: _withModifiers($setup.handleChangePassword, ["prevent"]),
            class: "mx-auto my-5 w-100 rounded-pill",
            text: 'Enviar'
          }, {
            IconText: _withCtx(() => [
              _createVNode($setup["LoginIcon"], { class: "pt-1" })
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["loading"])),
      _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
      _createElementVNode("p", _hoisted_2, [
        _cache[7] || (_cache[7] = _createElementVNode("span", { class: "text-muted" }, "Versão: ", -1 /* HOISTED */)),
        (!$setup.state.isMobile)
          ? (_openBlock(), _createElementBlock("a", _hoisted_3, _toDisplayString(String($setup.version)), 1 /* TEXT */))
          : _createCommentVNode("v-if", true)
      ])
    ], 32 /* NEED_HYDRATION */),
    _createVNode($setup["MoleculeModalAlertRecoverPassword"], {
      noBackButton: "",
      width: "400px",
      "show-alert-confirm": $setup.showModalAlert,
      icon: 'PasswordSuccessIcon',
      textButton: "Finalizar",
      description: "Efetue o login com sua nova senha.",
      text: "Senha redefinida com sucesso!",
      onConfirm: $setup.handleFinsh
    }, null, 8 /* PROPS */, ["show-alert-confirm"])
  ], 64 /* STABLE_FRAGMENT */))
}