import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "text-sm text-center" }
const _hoisted_2 = {
  key: 0,
  class: "text-forgot cursor-pointer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("form", {
      class: "area-form",
      onSubmit: _withModifiers($setup.handleSendEmail, ["prevent"])
    }, [
      _cache[3] || (_cache[3] = _createElementVNode("img", {
        src: "assets/GoConnect-logo.svg",
        class: "mb-12",
        alt: "logo"
      }, null, -1 /* HOISTED */)),
      _createVNode($setup["AtomTitle"], {
        size: "32px",
        title: 'Esqueceu sua senha?'
      }),
      _createVNode($setup["AtomSubTitle"], {
        size: "16px",
        text: 'Fique tranquilo! Enviaremos um e-mail para redefini-la.'
      
      }),
      _createVNode($setup["AtomInput"], {
        hasError: $setup.listError.includes('email'),
        "msg-error": "Campo obrigatório.",
        label: !$setup.state.isMobile ? 'Email*' : 'E-mail',
        placeholder: !$setup.state.isMobile ? 'exemplo@email.com' : 'Digite seu usuário',
        prependIcon: "",
        modelValue: $setup.email,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.email) = $event)),
        class: "mb-5"
      }, {
        prependIcon: _withCtx(() => [
          (!$setup.state.isMobile)
            ? (_openBlock(), _createBlock($setup["MessageIcon"], {
                key: 0,
                color: $setup.listError.includes('email') ? 'var(--color-error)' : ''
              }, null, 8 /* PROPS */, ["color"]))
            : (_openBlock(), _createBlock($setup["ProfileIconLogin"], {
                key: 1,
                color: $setup.listError.includes('email') ? 'var(--color-error)' : ''
              }, null, 8 /* PROPS */, ["color"]))
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["hasError", "label", "placeholder", "modelValue"]),
      (!$setup.state.isMobile)
        ? (_openBlock(), _createBlock(_component_v_btn, {
            key: 0,
            loading: $setup.loading,
            onClick: $setup.handleSendEmail,
            variant: "flat",
            color: "primary",
            class: "w-100 my-5 mt-9"
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode(" Enviar ")
            ])),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["loading"]))
        : (_openBlock(), _createBlock($setup["AtomButton"], {
            key: 1,
            center: "text-center",
            IconText: "",
            loading: $setup.loading,
            onClick: _withModifiers($setup.handleSendEmail, ["prevent"]),
            class: "mx-auto my-5 w-100 rounded-pill",
            text: 'Login'
          }, {
            IconText: _withCtx(() => [
              _createVNode($setup["LoginIcon"], { class: "pt-1" })
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["loading"])),
      _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
      _createElementVNode("p", _hoisted_1, [
        _cache[2] || (_cache[2] = _createElementVNode("span", { class: "text-muted" }, "Versão: ", -1 /* HOISTED */)),
        (!$setup.state.isMobile)
          ? (_openBlock(), _createElementBlock("a", _hoisted_2, _toDisplayString(String($setup.version)), 1 /* TEXT */))
          : _createCommentVNode("v-if", true)
      ])
    ], 32 /* NEED_HYDRATION */),
    _createVNode($setup["MoleculeModalAlertRecoverPassword"], {
      noBackButton: "",
      width: "400px",
      "show-alert-confirm": $setup.showModalAlert,
      icon: 'EmailIcon',
      textButton: "Finalizar",
      description: "Se este e-mail estiver em nossa base de dados, iremos enviar um link para redefinição de sua senha.",
      text: "E-mail enviado com sucesso!",
      onConfirm: $setup.handleFinsh
    }, null, 8 /* PROPS */, ["show-alert-confirm"])
  ], 64 /* STABLE_FRAGMENT */))
}