import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "120",
  height: "120",
  viewBox: "0 0 120 120",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("rect", {
      width: "120",
      height: "120",
      rx: "60",
      fill: "#FF5C00",
      "fill-opacity": "0.1"
    }, null, -1 /* HOISTED */),
    _createElementVNode("path", {
      d: "M80.0002 49.3333C83.6821 49.3333 86.6668 46.3486 86.6668 42.6667C86.6668 38.9848 83.6821 36 80.0002 36C76.3183 36 73.3335 38.9848 73.3335 42.6667C73.3335 46.3486 76.3183 49.3333 80.0002 49.3333Z",
      fill: "#FF5C00"
    }, null, -1 /* HOISTED */),
    _createElementVNode("path", {
      d: "M80.0002 49.3333C83.6821 49.3333 86.6668 46.3486 86.6668 42.6667C86.6668 38.9848 83.6821 36 80.0002 36C76.3183 36 73.3335 38.9848 73.3335 42.6667C73.3335 46.3486 76.3183 49.3333 80.0002 49.3333Z",
      fill: "#FF5C00"
    }, null, -1 /* HOISTED */),
    _createElementVNode("path", {
      d: "M83.2535 52.8267C81.3868 53.4133 79.3335 53.52 77.2002 52.9867C73.6268 52.0533 70.7202 49.2 69.7335 45.6267C69.2535 43.8933 69.2268 42.1867 69.5202 40.64C69.8668 38.9333 68.6668 37.3333 66.9602 37.3333H46.6668C38.6668 37.3333 33.3335 41.3333 33.3335 50.6667V69.3333C33.3335 78.6667 38.6668 82.6667 46.6668 82.6667H73.3335C81.3335 82.6667 86.6668 78.6667 86.6668 69.3333V55.36C86.6668 53.6 84.9602 52.2667 83.2535 52.8267ZM69.3868 57.7333L66.2402 60.24C64.4802 61.6533 62.2402 62.3467 60.0002 62.3467C57.7602 62.3467 55.4935 61.6533 53.7602 60.24L45.4135 53.5733C44.5602 52.88 44.4268 51.6 45.0935 50.7467C45.7868 49.8933 47.0402 49.7333 47.8935 50.4267L56.2402 57.0933C58.2668 58.72 61.7068 58.72 63.7335 57.0933L66.8802 54.5867C67.7335 53.8933 69.0135 54.0267 69.6802 54.9067C70.3735 55.76 70.2402 57.04 69.3868 57.7333Z",
      fill: "#FF5C00"
    }, null, -1 /* HOISTED */)
  ])))
}