import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "subtitle mt-2" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "border rounded-lg pa-6 bg-stroke" }
const _hoisted_6 = { class: "title" }
const _hoisted_7 = { class: "subtitle mt-2" }
const _hoisted_8 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock("div", null, [
    ($setup.props.data.type === 'text')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("p", _hoisted_2, _toDisplayString($setup.props.data.title), 1 /* TEXT */),
          _createElementVNode("p", _hoisted_3, _toDisplayString($setup.props.data.text), 1 /* TEXT */)
        ]))
      : ($setup.props.data.type === 'card')
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.props.data.cards, (card) => {
                  return (_openBlock(), _createBlock(_component_v_col, {
                    key: String(card.title),
                    cols: "12",
                    md: "6"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("p", _hoisted_6, _toDisplayString(card.title), 1 /* TEXT */),
                        _createElementVNode("p", _hoisted_7, _toDisplayString(card.text), 1 /* TEXT */)
                      ])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */))
                }), 128 /* KEYED_FRAGMENT */))
              ]),
              _: 1 /* STABLE */
            })
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createVNode($setup["OrganismsDetailChart"], {
              data: $setup.props.data.series,
              title: $setup.props.data.title
            }, null, 8 /* PROPS */, ["data", "title"])
          ]))
  ]))
}